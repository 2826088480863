<template>
    <div>
        <router-view/>
    </div>
    
</template>

<style>
    body {
        background-image: url('https://w0.peakpx.com/wallpaper/114/617/HD-wallpaper-city-night-ultra-city-beautiful-urban-reflection-skyline.jpg');
        background-repeat: no-repeat;
        background-size: 100% 100%;
        background-attachment: fixed;
        backdrop-filter: blur(2px);
    }
</style>