import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'homepage',
    component: ()=>import('../views/Home.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: ()=>import('../views/Admin.vue')
  },
  {
    path: '/services',
    name: 'services',
    component: ()=>import('../views/Services.vue')
  },
  {
    path: '/service/:id',
    name: 'service',
    component: ()=>import('../views/Service.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: ()=>import('../views/Login.vue'),
  },
  { path: '/:pathMatch(.*)*', redirect: '/' },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
